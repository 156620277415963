import React from 'react';
import '../css/ModuleBlock.css'



function ModuleBlock(props) {
    return (
        <div className='ButtonContainer'>
            <button className="rectangle-button" style={{ backgroundImage: props.ModuleImage }} onClick={props.ModelType}>
                <span className="button-text">{props.ModuleName}</span>
            </button>
        </div>

    );
  }
  
  export default ModuleBlock;