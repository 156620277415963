import React, { useRef, useState } from 'react';
import './components/css/app.css'; 
import './components/css/background.css'; 
import ChatComponent from './components/scripts/ChatComponent';
import Title from './components/scripts/textanimation';
import ModuleBlock from './components/scripts/ModuleBlock';

function App() {
  const [student, setStudent] = useState(false);
  const imageRef = useRef(null);


  const pageHandleChangeStudent = () => {
    setStudent(true);
  };

  const pageHandleChangeInitial = () => {
    setStudent(false);
  };

  const scrollToImage = () => {
    if (imageRef.current) {
      imageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div className='container'>
      {!student && (
        <>
          <div className="centered">
            <img className="logo" src="/AMLogo.png" alt="AM Logo" />
            <div className="title-wrapper">
              <Title textData="Wisdom Keeper" />
            </div>
            <p>
              The Aventis Wisdom Keeper is an AI Assistant created by the Aventis Metaverse team.
              <br />Its aim is to aid students in their modules.
            </p>
            <button className="customButton" onClick={scrollToImage}>Get Started</button>
          </div>

          {/* Modules Section */}
          <div className="modules-section" ref={imageRef}>
            <ModuleBlock ModuleName="AI Strategy For Businesses" ModuleImage="url('/Background.webp')" ModelType={pageHandleChangeStudent} />
            {/* Add more ModuleBlock components as needed */}
          </div>
        </>
      )}
      {student && (
        <div>
          <ChatComponent/>
          <div style={{ position:"relative", display: "flex", justifyContent: "center",
          alignItems: "center",
          margin: "0 auto" }}>
            <button className="return" onClick={pageHandleChangeInitial}>Return</button>
          </div>
        </div>

        
        )}

    </div>
  );
}

export default App;