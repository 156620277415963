import { useState, useEffect }  from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import '../css/ChatComponent.css';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';

const API_KEY = process.env.REACT_APP_API_KEY;

const ChatComponent = () => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm the Wisdom Keeper for the AI Strategy for Business Module",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSendRequest = async (message) => { 

    let tmpMessage = message 

    const newMessage = {
        message : tmpMessage,
        direction: 'outgoing',
        sender: "user",
      };

      console.log(newMessage); 
      console.log(newMessage.message);
  
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTyping(true);

    try {
      const response = await processMessageToChatGPT([...messages, newMessage]);
      const content = response.choices[0]?.message?.content;

      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: "ChatGPT",
        };
        setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
      }
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    let Testcontent = `
      You are the Wisdom Keeper, an AI Assistant for the module on AI Strategy for business. Your role is to help students who have inquiries or questions on our module. You are only allowed to answer questions relevant to the module, and not irrelevant questions or questions that might provide a possible cheat to the final assessment.
    `
    const apiMessages = chatMessages.map((messageObject) => {
        const role = messageObject.sender === "ChatGPT" ? "system" : "user";
        return { role, content: messageObject.message };
      });
  
      const apiRequestBody = {
        "model": "ft:gpt-4o-mini-2024-07-18:personal:aisfb-model-final:A3yKIIaU",
        "messages": [
          { role: "system", content: Testcontent },
          ...apiMessages,
        ],
        temperature: 0.7,           // Controls creativity/randomness (0.0 - 1.0)
        max_tokens: 500,            // Maximum number of tokens to generate
        top_p: 0.9,                 // Nucleus sampling (0.0 - 1.0, with 1.0 including all token possibilities)
        frequency_penalty: 0.5,     // Penalize repeated phrases (-2.0 to 2.0)
        presence_penalty: 0.6    
      };
  
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiRequestBody),
      });
  
      return response.json();
  
  }

  return (
    <div style={{ position:"relative", height: "92vh", width: "700px", display: "flex", justifyContent: "center",
    alignItems: "center",
    margin: "0 auto"  }}>
      <MainContainer>
        <ChatContainer>       
          <MessageList 
            scrollBehavior="smooth" 
            typingIndicator={isTyping ? <TypingIndicator content="Teaching Assistant is typing" /> : null}
          >
            {messages.map((message, i) => {
              //console.log(message)
              return <Message key={i} model={message} />
            })}
          </MessageList>
          <MessageInput placeholder="Send a Message" onSend={handleSendRequest} />        
        </ChatContainer>

      </MainContainer>
    </div>
  );
};

export default ChatComponent;
